import React from "react";
import { withAuth } from "../auth/authWrappers";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguageSelect";
import { Stack, Typography } from "@mui/material";

const ProfilePage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Stack justifyContent="space-between" direction="column">
            <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
                <b>{t('Settings')}</b>
            </Typography>
            <LanguageSelect />
        </Stack>
    );
};

export default withAuth(ProfilePage);
