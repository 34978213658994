import React from 'react'
import Select, { ISelectProps } from './Select'
import { ISelectOption } from './Select.types'

interface IFetcherSelectProps extends Omit<ISelectProps, 'options'> {
  // Options accepts hooks such as useGetAvailabilitiesQuery
  //TODO: find a proper type
  options: any
}

export const FetcherSelect = React.forwardRef(
  ({ options, ...props }: IFetcherSelectProps, ref) => {
    const { data } = options()

    const opts: ISelectOption<number>[] = (data || []).map((opt: any) => ({
      value: opt.id,
      label: opt.name
    }))

    return data ? (
      <Select options={opts} ref={ref} {...props} />
    ) : (
      <>Loading options...</>
    )
  }
)

export default FetcherSelect
