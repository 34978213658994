import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
} from "recharts";
import Card from "@mui/material/Card";

function randn_bm(min = 1, max = 1, skew = 1) {
  let u = 0,
    v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

  num = num / 10.0 + 0.5; // Translate to 0 -> 1
  if (num > 1 || num < 0)
    num = randn_bm(min, max, skew); // resample between 0 and 1 if out of range
  else {
    num = Math.pow(num, skew); // Skew
    num *= max - min; // Stretch to fill range
    num += min; // offset to min
  }
  return num;
}

export default function AnomalyViewer() {
  const [data_a, setData_a] = useState<any[]>([]);
  const [data_b, setData_b] = useState<any[]>([]);

  useEffect(() => {
    // populate data_1 with random data with a normal distribution with mean 0 and std 1
    // populate data_2 with random data with a normal distribution with mean 3 and std 2

    const data_a = [];
    const data_b = [];

    for (let i = 0; i < 100; i++) {
      data_a.push({
        x: randn_bm(2, 1, 2),
        y: randn_bm(2, 1, 2),
      });
      data_b.push({
        x: randn_bm(3, 2, 2),
        y: randn_bm(3, 2, 2),
      });
    }

    setData_a(data_a);
    setData_b(data_b);
  }, []);

  return (
    <>
      <Stack justifyContent="space-between" direction="row">
        <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
          <b>Anomaly viewer</b>
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent={"center"}>
        <Card
          sx={{
            bgcolor: "#FFFFFF",
            borderRadius: "22px",
            padding: "50px",
            boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
            marginTop: "10px",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            <b>Scatter plot</b>
          </Typography>
          <br />
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid />
              <XAxis type="number" dataKey="x" name="stature" unit="cm" />
              <YAxis type="number" dataKey="y" name="weight" unit="kg" />
              <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              <Scatter name="A school" data={data_a} fill="#8884d8" />
              <Scatter name="B school" data={data_b} fill="#FA1010" />
            </ScatterChart>
          </ResponsiveContainer>
        </Card>
      </Stack>
    </>
  );
}
