import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { INoteRecord, INoteRequest } from "./noteTypes";
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetNoteQuery, useCreateNoteMutation, useUpdateNoteMutation } from '../../app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller, FormProvider } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Quill from './Quill';
import TagSelect from '../tags/TagSelect';

interface INoteFormDialogProps {
    noteId?: INoteRecord["id"];
    render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing notes.
 * It can be opened in two modes:
 * 1. Create mode: noteId is not given
 * 2. Edit mode: noteId is given
 *
 * In edit mode, the note data is fetched from the API so
 * that the form can be pre-filled with the existing note data.
 */
const NoteFormDialog: React.FC<INoteFormDialogProps> = ({ noteId, render }) => {
    const [open, setOpen] = useState(false);
    const createMode: boolean = !noteId;

    // Delay data fetching until dialog is opened
    const { data: note } = useGetNoteQuery(noteId || -1, { skip: createMode || !open });

    const [triggerCreate, {
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        isError: isCreateError

    }] = useCreateNoteMutation();
    const [triggerUpdate, {
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError
    }] = useUpdateNoteMutation();

    // defaultValues are only available after note data is fetched.
    // If note data is not fetched, but noteId is given, show loading indicator
    // If note data is not fetched and noteId is not given, show empty form
    const defaultNoteValues: INoteRequest | undefined =
        // In create mode, show empty form without fetching note data
        createMode ? {
            title: "",
            body: "",
            body_html: "",
            log_record_ids: [], tag_names: []
        } :
            // In edit mode show loading until note data is fetched
            note ?
                // Note is fetched, show note data
                ({
                    title: note.title,
                    body: note.body,
                    body_html: note.body_html,
                    log_record_ids: note.log_records.map(log => log.id),
                    tag_names: note.tags.map(tag => tag.name)
                }) :
                // Note is not fetched
                undefined

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = (data: INoteRequest) => {
        if (createMode) {
            triggerCreate(data);
        } else {
            triggerUpdate({
                id: noteId || -1, ...data
            });
        }
    };

    // Handle error and success notifications
    useEffect(() => {
        if (isCreateError) {
            enqueueSnackbar("Errore creazione", { variant: "error" });
        }
        if (isCreateSuccess) {
            enqueueSnackbar("Creato con successo", { variant: "success" });
            handleClose();
        }
        if (isUpdateError) {
            enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
        }
        if (isUpdateSuccess) {
            enqueueSnackbar("Aggiornato con successo", { variant: "success" });
            handleClose();
        }
    }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

    const openButton = render ? render({ onClick: () => setOpen(true) }) : <IconButton onClick={() => setOpen(true)}><EditOutlinedIcon /></IconButton>;

    return (
        <>
            {openButton}
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{createMode ? "Crea nuova nota" : "Modifica nota"}</DialogTitle>
                {defaultNoteValues ? (
                    <NoteForm
                        createMode={createMode}
                        isLoading={isCreateLoading || isUpdateLoading}
                        onSubmit={handleSubmit}
                        onClose={handleClose}
                        defaultValues={defaultNoteValues} />
                ) : (
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}


interface INoteFormProps {
    defaultValues: INoteRequest;
    createMode?: boolean;
    isLoading: boolean;
    onSubmit: (data: INoteRequest) => void;
    onClose: () => void;
}


/**
 * Inner form component for NoteFormDialog.
 * Separate component is used to render only when the
 * defaultValues are available in case of edit mode.
 */
const NoteForm: React.FC<INoteFormProps> = ({ defaultValues, onSubmit, onClose, isLoading, createMode }) => {

    const form = useForm<INoteRequest>({ defaultValues });
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack gap={2}>
                        <Controller
                            name="title"
                            rules={{ required: true, minLength: 3 }}
                            control={form.control}
                            render={({ field, fieldState }) => (
                                <TextField
                                    autoFocus
                                    label={"Titolo"}
                                    type="text"
                                    fullWidth
                                    required
                                    error={!!fieldState.error}
                                    helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="tag_names"
                            control={form.control}
                            render={({ field }) => (
                                <TagSelect
                                    {...field}
                                />
                            )}
                        />
                        <Quill htmlFieldName='body_html' textFieldName='body' />
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ m: 2 }}>
                    <Button
                        startIcon={<CloseOutlinedIcon />}
                        onClick={onClose}>Annulla</Button>
                    <LoadingButton
                        startIcon={<CheckOutlinedIcon />}
                        loading={isLoading}
                        disabled={isLoading}
                        variant='contained' type="submit">{createMode ? "Crea" : "Aggiorna"}</LoadingButton>
                </DialogActions>
            </form>
        </FormProvider>


    );
}


export default NoteFormDialog;