import { useRef, useState } from "react";
import { Language } from "../../app/services/apiTypes";
import {
  useCreateChatMessageMutation,
  useGetChatQuery,
} from "../../app/services/appApi";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import Stack from "@mui/system/Stack";
import ChatHistory from "./ChatHistory";
import { useAssistantContext } from "./useAssistantContext";
import { useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

interface IChatbotColumnProps {
  onMessageSubmit?: (body: string) => void;
  autoStartMessage?: string;
}

export default function ChatbotColumn({ onMessageSubmit, autoStartMessage }: IChatbotColumnProps) {
  const { i18n } = useTranslation();
  const chatHistoryRef = useRef<HTMLDivElement | null>(null);
  const [message, setMessage] = useState("");

  const {
    relevLogRecordId,
    relevDeviceId
    //  relevDocumentationIds
  } = useAssistantContext();


  const [createChatMessage, {
    data: lastChatMessage,
    // isLoading: isChatCreating,
    // isError: isChatCreationError,
  }] = useCreateChatMessageMutation();

  const location = useLocation();
  const navigate = useNavigate();

  // Auto-trigger the chat if the ?startchat= query parameter is present. This is needed to launch the assistant from a link.
  // Removes the query parameter from the URL after opening the chat.
  // This is triggered only if component is mounted, so app must ensure the component is shown when the parameter is present.
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has('startchat')) {
      // Params are present, start the chat
      handleMessageSumbit(params.get('startchat') || '');
      // Remove the startchat parameter from the URL to prevent re-triggering the chat
      params.delete('startchat');
      navigate({ search: params.toString() });
    }
  }, [location.search]);

  // Fetch the chat (with streaming updates) only if the chatId is available
  const CHAT_UNINITIALIZED = -1;
  const chatId: number = lastChatMessage?.chat.id || CHAT_UNINITIALIZED;
  const { data: chat } = useGetChatQuery(chatId, {
    skip: chatId === CHAT_UNINITIALIZED,
  });

  useEffect(() => {
    // Scroll to the bottom of the chat history whenever a new message is added
    chatHistoryRef.current?.scrollTo(0, chatHistoryRef.current.scrollHeight);
  }, [chat?.messages]);


  // const chatStreamCleaned = chatStream?.replace("<EOF>", "");
  // const isChatStreamEnded = chatStream && chatStream.endsWith("<EOF>");
  // const isChatStreamBuffering = chatStream === "";

  // const messages: IChatMessage[] = chatStreamCleaned
  //   ? [
  //     {
  //       text: chatStreamCleaned,
  //       user: "bot",
  //     },
  //   ]
  //   : [];

  const handleMessageSumbit = (body: string) => {
    setMessage("");
    // If parent component provided a callback, call it
    onMessageSubmit && onMessageSubmit(body);
    // Start a chat with the provided context
    // TODO: fix this
    createChatMessage(
      {
        chatId: chatId === CHAT_UNINITIALIZED ? undefined : chatId,
        chatMessage: {
          body,
          language: i18n.language as Language,
          context_log_record_id: relevLogRecordId,
          context_device_id: relevDeviceId
        }
      });
  };

  const { t } = useTranslation();


  // Start the chat stream as soon as the chat is created.
  // skip the effect if the chat stream is already started
  // to avoid creating multiple streams
  // useEffect(() => {
  //   if (chat && !chatStream) {
  //     startStream(chat.id);
  //   }
  // }, [chat, chatStream, startStream]);

  // // Handle errors
  // useEffect(() => {
  //   if (isChatCreationError) {
  //     enqueueSnackbar("Errore nella creazione della chat", {
  //       variant: "error",
  //     });
  //   }
  // }, [isChatCreationError]);

  {/* <Grid container spacing={0} sx={{ width: "100vw", maxWidth: "980px", height: "100vh", backgroundColor: "red" }}>
    <Grid xs={12} sm={5} sx={{ backgroundColor: "green" }}>
    </Grid>
    <Grid xs={12} sm={7} sx={{ backgroundColor: "blue" }}>
    </Grid>
</Grid> */}



  return (
    <Box
      style={{
        backgroundColor: "#F3F5F7",
        height: "100vh",
        // width: "60%",
        // maxWidth: "600px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 1, overflowY: "auto" }}

        ref={chatHistoryRef}

      >

        <ChatHistory
          chat={chat}
          // loading={isChatCreating || isChatStreamBuffering}
          onStartChat={() => handleMessageSumbit(t('How can I solve this problem?'))}
          loading={false}
        />
      </div>

      {/* {chat.messages.length !== 0 && isChatStreamEnded && (
        <QuickReplyChips
          messages={[
            "Proponi una soluzione alternativa",
            "Spiega in parole più semplici",
            "Fornisci maggiori dettagli",
          ]}
          onClick={handleMessageRequest}
        />
      )} */}

      {/* Reply textfield with send button on the right with right arrow icon */}
      <form onSubmit={(e) => { e.preventDefault(); handleMessageSumbit(message) }}>

        <Stack direction="row" sx={{ p: 2 }}>
          <TextField
            variant="outlined"
            placeholder={t('Type a message')}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </form>


    </Box>
  );
}

// interface IQuickReplyChipsProps {
//   messages: string[];
//   onClick?: (message: string) => void;
// }

// function QuickReplyChips({ onClick, messages }: IQuickReplyChipsProps) {
//   return (
//     <Stack gap={2} sx={{ px: 3 }}>
//       {messages.map((message) => (
//         <Chip
//           key={message}
//           label={message}
//           onClick={() => onClick?.(message)}
//         />
//       ))}
//     </Stack>
//   );
// }
