import React, { useEffect } from "react";
import {
  useGetDevicesQuery,
  useDeleteDeviceMutation,
} from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { IDevice } from "../../app/services/apiTypes";
import { Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import { withAuth } from "../auth/authWrappers";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeviceFormDialog from './DeviceFormDialog';

const DevicePage = () => {
  const { t } = useTranslation();
  const { data } = useGetDevicesQuery();


  const columns: GridColDef[] = [
    {
      field: "id", headerName: "ID", width: 70
      // Align the column to the right
      , headerAlign: "right", align: "right"

    },
    {
      field: "name",
      headerName: t("Name"),
      // Grow the column width
      flex: 1,
    },
    {
      field: "tags",
      headerName: t("Tag", { count: 2 }),
      flex: 2,
      // render tag.name as string
      //@ts-ignore-next-line
      valueGetter: (params: GridValueGetterParams) => params.row.tags.map(tag => tag.name).join(", "),
    },
    {
      field: "edit", headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <DeviceFormDialog deviceId={params.row.id} />
      ),
    },
    {
      field: "delete", headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <DeviceDeleteBtn deviceId={params.row.id} />
      ),
    },
  ];

  return (
    <>
      <Stack justifyContent="space-between" direction="row">
        <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
          <b>{t("Device", { count: 2 })}</b>
        </Typography>
      </Stack>
      <DataGrid
        disableRowSelectionOnClick
        rows={data || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ height: "500px", my: 2 }}
      />
      <DeviceFormDialog />
    </>
  );
}





const DeviceDeleteBtn: React.FC<{ deviceId: Pick<IDevice, "id"> }> = ({ deviceId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteDeviceMutation();
  const { t } = useTranslation();

  // Handle error and success notifications
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Model deleted", { model: "Device", count: 1 }), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Model deletion failed", { model: "Device", count: 1 }), { variant: "error" });
    }
  }, [isSuccess, isError, t]);


  return (
    <IconButton
      onClick={() => triggerDelete(deviceId)}
      color="error"
      disabled={isLoading}
    >
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  )


}

export default withAuth(DevicePage);
