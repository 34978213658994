import { TextField, Stack } from '@mui/material';
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormContext } from 'react-hook-form';

interface QuillInputProps {
    textFieldName: string;
    htmlFieldName: string;
}

export default function QuillInput({ textFieldName, htmlFieldName }: QuillInputProps) {
    const { register, setValue, getValues } = useFormContext();
    const quillRef = useRef(null); // Create a ref object

    const handleChange = (_content: any, _delta: any, _source: any, editor: any) => {
        setValue(textFieldName, editor.getText());
        setValue(htmlFieldName, editor.getHTML());
    };

    return (
        <>
            <ReactQuill
                theme="snow"
                defaultValue={getValues(htmlFieldName || textFieldName || '')}
                onChange={handleChange}
                ref={quillRef}
            />
            <Stack direction="row" gap={1}>
                <input type="hidden" {...register(textFieldName)} />
                <input type="hidden" {...register(htmlFieldName)} />
            </Stack>
        </>
    );
}