import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IChat,
  IDebugResponse,
  ISearchResponse,
  ILogRecord,
  ILoginRequest,
  ILoginResponse,
  IUserResponse,
  ILogRecordRequest,
  IChatMessage,
  IChatMessageRequest,
  IRelatedLogRecordResult,
  IDevice,
  IDeviceRequest,
  ITag,
  ISearchRequest,
  ITopKRequest,
  ITopKResponse
} from "./apiTypes";
import { INoteRecord, INoteRequest } from "../../features/note/noteTypes";

const API_PATH = "/api/v1/"; // Only url part, without localhost or IP. Must always end with a slash

const baseQuery =fetchBaseQuery({
  baseUrl: API_PATH,
  prepareHeaders: (headers, { getState }) => {
    // Add default Content-Type header unless they are already set. case-insensitive
    // if (!headers.has("Content-Type") && !headers.has("content-type")) {
    //   headers.set("Content-Type", "application/json");
    // }
    headers.set("Accept", "application/json; charset=utf-8");
    // Append token to every request
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithLogout = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  // Handle authentication errors
  if (result.error && result.error.status === 401) {
    // const isMissingToken = result.error.data.code === "MISSING_TOKEN";
    const isInvalidToken = (result.error.data as { code?: string })?.code === "INVALID_TOKEN";
    const isExpiredToken = (result.error.data as { code?: string })?.code === "EXPIRED_TOKEN";

    if (isInvalidToken || isExpiredToken) {
      console.log("Invalid or missing token, logging out...");
      localStorage.removeItem("token");
      // Invalidate Auth cache
      api.invalidateTags(["USER"]);
    }
  }

  return result;
};

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: baseQueryWithLogout,
  tagTypes: ["USER", "LOG_RECORD", "CHAT", "NOTE", "DEVICE", "TAG"],

  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: ({ username, password }) => ({
        url: "/user/login",
        method: "POST",
        body: { username, password },
      }),
      // Update auth only on successful attempt to refetch user data
      invalidatesTags: (result) => (result ? ["USER"] : []),
      // Keep token in localStorage
      transformResponse: (response: ILoginResponse) => {
        if (response.token) {
          localStorage.setItem("token", response.token);
        }
        return response;
      },
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/user/logout",
        method: "POST",
      }),
      invalidatesTags: ["USER"],
      transformResponse: (response: void) => {
        // Remove token from localStorage
        localStorage.removeItem("token");
        return response;
      },
    }),

    currentUser: builder.query<IUserResponse, void>({
      query: () => "/user/current",
      providesTags: ["USER"],
    }),

    getLogRecords: builder.query<ILogRecord[], { deviceId: number }>({
      query: ({deviceId}) => `/logrecord?device_id=${deviceId}`,
      providesTags: ["LOG_RECORD"],
    }),

    getDevices: builder.query<IDevice[], void>({
      query: () => `/device`,
      providesTags: ["DEVICE"],
    }),

    getDevice: builder.query<IDevice, IDevice["id"]>({
      query: (deviceId) => `/device/${deviceId}`,
      providesTags: ["DEVICE"],
    }),

    createDevice: builder.mutation<IDevice, IDeviceRequest>({
      query: (device) => ({
        url: `/device`,
        method: "POST",
        body: device,
      }),
      // Update tag list as they can be created when creating device
      invalidatesTags: ["DEVICE", "TAG"],
    }),

    updateDevice: builder.mutation<
      IDevice,
      IDeviceRequest & { id: IDevice["id"] }
    >({
      query: ({ id, ...device }) => ({
        url: `/device/${id}`,
        method: "PUT",
        body: device,
      }),
      // Update tag list as they can be created when updating device
      invalidatesTags: ["DEVICE", "TAG"],
    }),

    deleteDevice: builder.mutation<void, Pick<IDevice, "id">>({
      query: (deviceId) => ({
        url: `/device/${deviceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DEVICE"],
    }),

    getTags: builder.query<ITag[], void>({
      query: () => `/tag`,
      providesTags: ["TAG"],
    }),

    getRelatedLogRecords: builder.query<IRelatedLogRecordResult[], number>({
      query: (logRecordId) => `/logrecord/${logRecordId}/related`,
      providesTags: ["LOG_RECORD", "NOTE"],
    }),

    createLogRecord: builder.mutation<ILogRecord, ILogRecordRequest>({
      query: (logRecord) => ({
        url: `/logrecord`,
        method: "POST",
        body: logRecord,
      }),
      invalidatesTags: ["LOG_RECORD"],
    }),

    createChatMessage: builder.mutation<
      IChatMessage,
      { chatId?: number; chatMessage: IChatMessageRequest }
    >({
      query: ({ chatId, chatMessage }) => ({
        // If chatId is undefined, a new chat will be created by the server
        url: chatId ? `/chat/${chatId}/message` : `/chat/message`,
        method: "POST",
        body: chatMessage,
      }),
      invalidatesTags: ["CHAT"],
    }),

    getChat: builder.query<IChat, number>({
      query: (chatId) => `/chat/${chatId}`,
      providesTags: ["CHAT"],
      // transformResponse: (response: any) => {
      //   return ""; // Ignore response JSON, we're streaming data from websocket
      // },
      async onCacheEntryAdded(
        chatId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        // create a websocket connection when the cache subscription starts.
        // const wsBaseUrl =
        const lorem =
          localStorage.getItem("dbgLoremChat") === "true" ? "true" : "false";
        const wsBaseUrl =
          window.location.origin.replace(/^http/, "ws") + API_PATH;
        const ws = new WebSocket(
          `${wsBaseUrl}chat/${chatId}/stream?lorem=${lorem}`
        );
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            const msg = event.data;
            if (msg === "<SOF>") {
              // Start of file. Append new temp message
              // console.log("Start of file");
              updateCachedData((draft) => ({
                ...draft,
                messages: [
                  ...draft.messages,
                  {
                    id: -1,
                    body: "",
                  } as IChatMessage,
                ],
              }));
            } else if (msg === "<EOF>") {
              // End of file. Handle this case
              // console.log("End of file");
            } else {
              // console.log("Received message: ", msg);
              updateCachedData((draft) => ({
                ...draft,
                messages: [
                  // Override all messages except the last one
                  ...draft.messages.slice(0, -1),
                  // Override last message's body
                  {
                    ...draft.messages[draft.messages.length - 1],
                    body: draft.messages[draft.messages.length - 1].body + msg,
                  },
                ],
              }));
            }
          };
          ws.addEventListener("message", listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.close();
      },
    }),

    // createNote: builder.mutation<INote, INoteRequest>({
    //   query: (note) => ({
    //     url: `/note`,
    //     method: "POST",
    //     body: note,
    //   }),
    //   invalidatesTags: ["NOTE", "LOG_RECORD"],
    // }),

    // getNotes: builder.query<[] | [INoteSearchResult], ILogRecord>({
    //   query: (log) => `/logrecord/${log.id}/note/`,
    // }),

    debugInfo: builder.query<IDebugResponse, void>({
      query: () => `/debug`,
    }),

    topKSearch: builder.query<ITopKResponse, ITopKRequest>({
      query: (body) => ({
        url: `/search`,
        method: "POST",
        body,
      }),
    }),




    getNotes: builder.query<ISearchResponse<INoteRecord>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/note?${queryParams.toString()}`;
      },
        providesTags: ["NOTE"],
    }),

    getNote: builder.query<INoteRecord, INoteRecord["id"]>({
      query: (noteId) => `/note/${noteId}`,
      providesTags: ["NOTE"],
    }),

    createNote: builder.mutation<INoteRecord, INoteRequest>({
      query: (note) => ({
        url: `/note`,
        method: "POST",
        body: note,
      }),
      invalidatesTags: ["NOTE", "LOG_RECORD"],
    }),

    updateNote: builder.mutation<
      INoteRecord,
      INoteRequest & { id: INoteRecord["id"] }
    >({
      query: ({ id, ...note }) => ({
        url: `/note/${id}`,
        method: "PUT",
        body: note,
      }),
      invalidatesTags: ["NOTE"],
    }),

    deleteNote: builder.mutation<void, Pick<INoteRecord, "id">>({
      query: (noteId) => ({
        url: `/note/${noteId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["NOTE"],
    }),

    forceReindexAllNotes: builder.mutation<void, void>({
      query: () => ({
        url: `/note/force_reindex_all`,
        method: "POST"
      }),
      invalidatesTags: ["NOTE", "LOG_RECORD"],
    }),


    forceReindexAllFiles: builder.mutation<void, void>({
      query: () => ({
        url: `/file/force_reindex_all`,
        method: "POST"
      }),
    }),

  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useCurrentUserQuery,
  useGetLogRecordsQuery,
  useCreateChatMessageMutation,
  useGetChatQuery,
  useGetRelatedLogRecordsQuery,
  useDebugInfoQuery,
  useCreateLogRecordMutation,
  useGetDevicesQuery,
  useGetDeviceQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useGetTagsQuery,

  // Notes
  useGetNoteQuery,
  useGetNotesQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,

  // TopKSearch
  useTopKSearchQuery,

  // Debug
  useForceReindexAllFilesMutation,
  useForceReindexAllNotesMutation,
} = appApi;
