import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useGetTagsQuery } from "../../app/services/appApi";
import { ITag } from '../../app/services/apiTypes';

interface TagSelectOptions {
    value: string[];
    onChange: (value: string[]) => void;
}

const TagSelect = React.forwardRef<HTMLDivElement, TagSelectOptions>(({ value, onChange }, ref) => {
    const { t } = useTranslation();

    const { data } = useGetTagsQuery();

    const handleChange = (event: React.SyntheticEvent<Element, Event>, value: string[]) => {
        // Ensure all tags begin with a hash. If not, add it.
        const tags = value.map((tag: string) => tag.startsWith("#") ? tag : `#${tag}`);
        onChange && onChange(tags);
    }

    return (
        <Autocomplete
            value={value}
            onChange={handleChange}
            // limitTags={3}
            multiple
            disableCloseOnSelect
            options={(data || []).map((t: ITag) => t.name)}
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip color="info" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("Tag", { count: 2 })}
                    placeholder={t("Type and press Enter to add tags")}
                    inputRef={ref}
                />
            )}
        />
    );
})

export default TagSelect;
