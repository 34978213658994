import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IDevice, IDeviceRequest } from '../../app/services/apiTypes';
import TagSelect from '../tags/TagSelect';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { t } from 'i18next';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetDeviceQuery, useCreateDeviceMutation, useUpdateDeviceMutation } from '../../app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

interface IDeviceFormDialogProps {
    deviceId?: IDevice["id"];
}

/**
 * Dialog component for creating and editing devices.
 * It can be opened in two modes:
 * 1. Create mode: deviceId is not given
 * 2. Edit mode: deviceId is given
 *
 * In edit mode, the device data is fetched from the API so
 * that the form can be pre-filled with the existing device data.
 */
const DeviceFormDialog: React.FC<IDeviceFormDialogProps> = ({ deviceId }) => {
    const [open, setOpen] = useState(false);
    const createMode: boolean = !deviceId;

    // Delay data fetching until dialog is opened
    const { data: device } = useGetDeviceQuery(deviceId || -1, { skip: createMode || !open });

    const [triggerCreate, {
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        isError: isCreateError

    }] = useCreateDeviceMutation();
    const [triggerUpdate, {
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError
    }] = useUpdateDeviceMutation();

    // defaultValues are only available after device data is fetched.
    // If device data is not fetched, but deviceId is given, show loading indicator
    // If device data is not fetched and deviceId is not given, show empty form
    const defaultDeviceValues: IDeviceRequest | undefined =
        // In create mode, show empty form without fetching device data
        createMode ? { name: "", tag_names: [] } :
            // In edit mode show loading until device data is fetched
            device ?
                // Device is fetched, show device data
                ({
                    name: device.name,
                    tag_names: device.tags.map(tag => tag.name)
                }) :
                // Device is not fetched
                undefined

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = (data: IDeviceRequest) => {
        if (createMode) {
            triggerCreate(data);
        } else {
            triggerUpdate({
                id: deviceId || -1, ...data
            });
        }
    };

    // Handle error and success notifications
    useEffect(() => {
        if (isCreateError) {
            enqueueSnackbar(t("Model creation failed", { model: "Device", count: 1 }), { variant: "error" });
        }
        if (isCreateSuccess) {
            enqueueSnackbar(t("Model created", { model: "Device", count: 1 }), { variant: "success" });
            handleClose();
        }
        if (isUpdateError) {
            enqueueSnackbar(t("Model update failed", { model: "Device", count: 1 }), { variant: "error" });
        }
        if (isUpdateSuccess) {
            enqueueSnackbar(t("Model updated", { model: "Device", count: 1 }), { variant: "success" });
            handleClose();
        }
    }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);



    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <EditOutlinedIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{createMode ? t('Create new model', { model: "Device", count: 1 }) : t("Edit model", { model: "Device", count: 1 })}</DialogTitle>
                {defaultDeviceValues ? (
                    <DeviceForm
                        createMode={createMode}
                        isLoading={isCreateLoading || isUpdateLoading}
                        onSubmit={handleSubmit}
                        onClose={handleClose}
                        defaultValues={defaultDeviceValues} />
                ) : (
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}


interface IDeviceFormProps {
    defaultValues: IDeviceRequest;
    createMode?: boolean;
    isLoading: boolean;
    onSubmit: (data: IDeviceRequest) => void;
    onClose: () => void;
}


/**
 * Inner form component for DeviceFormDialog.
 * Separate component is used to render only when the
 * defaultValues are available in case of edit mode.
 */
const DeviceForm: React.FC<IDeviceFormProps> = ({ defaultValues, onSubmit, onClose, isLoading, createMode }) => {

    const {
        control,
        handleSubmit,
    } = useForm<IDeviceRequest>({ defaultValues });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Stack gap={2}>
                    <Controller
                        name="name"
                        rules={{ required: true, minLength: 3 }}
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                autoFocus
                                label={t("Name")}
                                type="text"
                                fullWidth
                                required
                                error={!!fieldState.error}
                                helperText={!!fieldState.error && t("Field must be at least N characters", { field: "Name", min: 3 })}
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="tag_names"
                        control={control}
                        render={({ field }) => (
                            <TagSelect
                                {...field}
                            />
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ m: 2 }}>
                <Button
                    startIcon={<CloseOutlinedIcon />}
                    onClick={onClose}>{t("Cancel")}</Button>
                <LoadingButton
                    startIcon={<CheckOutlinedIcon />}
                    loading={isLoading}
                    disabled={isLoading}
                    variant='contained' type="submit">{createMode ? t('Create') : t("Update")}</LoadingButton>
            </DialogActions>
        </form>


    );
}


export default DeviceFormDialog;