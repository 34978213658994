import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { withAuth } from "./authWrappers";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import useAuthContext from "./AuthContext";
import { useTranslation } from "react-i18next";
const ProfilePage: React.FC = () => {
  const { user, logout } = useAuthContext();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 4,
      }}
    >
      <AccountCircleOutlinedIcon fontSize="large" />
      <Typography variant="h5" sx={{ mt: 2 }}>
        {user && user.username}
      </Typography>
      <Button sx={{ mt: 3 }} variant="contained" onClick={logout}>
        {t("Logout")}
      </Button>
    </Box>
  );
};



export default withAuth(ProfilePage);
