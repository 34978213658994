import React from 'react'
import { ITopKResult } from '../../app/services/apiTypes'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

export interface IPdfViewerProps {
    resource: ITopKResult
}


export default function PdfViewer({ resource }: IPdfViewerProps) {
    return (
        resource.thumbnail ? (
            <img
                src={`/api/v1/search/get_temp_search_img?filename=${resource.thumbnail}`}
                alt='resource'
                style={{
                    minWidth: '400px', // minimum width before scrolling
                    maxWidth: '100%', // max out the width of the container
                    height: 'auto', // maintain aspect ratio
                    objectFit: "cover"
                }}
            />) : <Box sx={{ bgcolor: 'background.paper', p: 4, }}>
            <Typography variant="h6">No preview available</Typography>
        </Box>

    )
}
