import { useState } from "react";
import { useGetLogRecordsQuery, useGetDevicesQuery } from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import LogDetailsDrawer from "../assistant/LogDetailsDrawer";
import { ILogRecord } from "../../app/services/apiTypes";
import { Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import RemoteShellDialog from "../remoteshell/RemoteShellDialog";
import LogLevelChip from "./LogLevelChip";
import { withAuth } from "../auth/authWrappers";
import { useAssistantContext } from "../assistant/useAssistantContext";
import { useTranslation } from "react-i18next";
import { FetcherSelect } from '../input/select/FetcherSelect';

const columns: GridColDef[] = [
  {
    field: "level",
    headerName: "Level", //TODO: translate
    width: 130,
    //@ts-ignore-next-line
    renderCell: (params: GridValueGetterParams) => (
      <LogLevelChip
        level={params.value as ILogRecord["level"]}
        variant="outlined"
      />
    ),
  },
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "created_at",
    headerName: "Timestamp",//TODO: translate
    width: 180,
  },
  {
    field: "body",
    headerName: "Message",//TODO: translate
    // expand row to fill remaining space
    flex: 1,
  },
];

function LogViewer() {
  const { t } = useTranslation();
  const { setRelevLogRecordId,
    relevDeviceId, setRelevDeviceId
  } = useAssistantContext();
  const { data } = useGetLogRecordsQuery({ deviceId: relevDeviceId }, { pollingInterval: 3000 });


  const [selectedLogRecord, setSelectedLogRecord] = useState<ILogRecord | null>(null);


  const handleLogRecordSelect = (logRecord: ILogRecord | null) => {
    setSelectedLogRecord(logRecord);
    // update the relevantLogRecord in the assistant context
    setRelevLogRecordId(logRecord ? logRecord.id : undefined);
  }

  return (
    <>
      <Stack justifyContent="space-between" direction="row">
        <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
          <b>{t('Log Viewer')}</b>
        </Typography>
        <FetcherSelect
          label={t("Device", { count: 1 })}
          options={useGetDevicesQuery}
          sx={{ width: "250px" }}
          size="medium"
          value={relevDeviceId}
          onChange={(e) => setRelevDeviceId(e.target.value as number)}
        />
      </Stack>
      <DataGrid
        rows={data || []}
        columns={columns}
        onRowClick={(params) => handleLogRecordSelect(params.row)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ height: "500px", mt: 2 }}
      />
      <RemoteShellDialog sx={{ mt: 2, float: "right" }} />
      <LogDetailsDrawer
        logRecord={selectedLogRecord}
        onClose={() => handleLogRecordSelect(null)}
      />
    </>
  );
}

export default withAuth(LogViewer);
