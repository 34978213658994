import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { ReactComponent as AppLogo } from "../../assets/mira_logo.svg";
import Stack from "@mui/system/Stack";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { IChat, IChatMessage } from "../../app/services/apiTypes";
import PersonIcon from '@mui/icons-material/Person';
import { t } from 'i18next';
import ReactMarkdown from 'react-markdown';
import { FetcherSelect } from '../input/select/FetcherSelect';
import { useGetDevicesQuery } from "../../app/services/appApi";
import { useAssistantContext } from "./useAssistantContext";

interface IChatHistoryProps {
  chat?: IChat;
  onStartChat: () => void;
  loading: boolean;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginTop: theme.spacing(2),
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
  backgroundColor: "white",
}));

const LargeStyledAvatar = styled(StyledAvatar)(({ theme }) => ({
  width: theme.spacing(9),
  height: theme.spacing(9),
  boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.15)",
  margin: theme.spacing(2),
}));

const AvatarWithIcon = ({ Icon, large }: { Icon: React.ReactElement, large?: boolean }) => {
  const AvatarComponent = large ? LargeStyledAvatar : StyledAvatar;

  return (
    <AvatarComponent>
      {Icon}
    </AvatarComponent>
  );
};

const botAvatar = <AvatarWithIcon Icon={<AppLogo />} />;
const largeBotAvatar = <AvatarWithIcon Icon={<AppLogo />} large />;
const userAvatar = <AvatarWithIcon Icon={<PersonIcon color="primary" />} />;

export default function ChatHistory({ ...props }: IChatHistoryProps) {

  const { chat, loading } = props;
  const messages = chat?.messages || [];

  return (
    <>
      {loading || messages.length === 0 ? (
        <EmptyHistoryPlaceholder {...props} />
      ) : (
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" component="div" align="center">
            {t('AI Assistant')}
          </Typography>
          {chat && chat.messages.length > 0 && (
            <Typography variant="body2" component="div" align="center">
              {t('Device', { count: 1 })}: {chat.messages[0].context_device.name || t('Unknown')}
            </Typography>)
          }
          <List>
            {messages.map((chatMessage) => (
              <ChatMessage
                key={chatMessage.id}
                chatMessage={chatMessage}
                alignRight={!chatMessage.author}
              />
            ))}
          </List>
        </Box>
      )}
    </>
  );
}

const ChatMessage: React.FC<{ chatMessage: IChatMessage; alignRight?: boolean }> = ({ chatMessage, alignRight }) => (
  <ListItem alignItems="flex-start" sx={{
    px: 0,
    py: 0.75
  }}>
    {!alignRight && <ListItemAvatar>{userAvatar}</ListItemAvatar>}
    <ListItemText
      sx={{
        bgcolor: "background.paper",
        boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        position: "relative",
        padding: "16px 24px",
        "&::before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: "30px",
          [alignRight ? "right" : "left"]: "-10px",
          width: "0",
          height: "0",
          borderTop: "10px solid transparent",
          borderBottom: "10px solid transparent",
          [alignRight ? "borderLeft" : "borderRight"]: "10px solid #fff",
        },
      }}
      primary={
        <>

          <Typography
            component="span"
            variant="body2"
            color="text.primary"
          // sx={{
          //   whiteSpace: "pre-line"
          // }}
          >
            <ReactMarkdown>{chatMessage.body || "..."}</ReactMarkdown>
          </Typography>
          {/* <Button
            sx={{ my: 1 }}
            onClick={() => { }}
            fullWidth
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<DescriptionIcon />}
          >
            Apri documento
          </Button> */}
        </>
      }
    />

    {alignRight && <ListItemAvatar sx={{ ml: 2 }}>{botAvatar}</ListItemAvatar>}
  </ListItem>
);


function EmptyHistoryPlaceholder({ onStartChat, loading }: IChatHistoryProps) {

  const { relevDeviceId, setRelevDeviceId } = useAssistantContext()

  return (
    <Stack alignItems="center" sx={{ textAlign: "center", p: 4 }}>
      {largeBotAvatar}
      <Typography variant="h6" sx={{ mt: 2 }}>
        <b>{t('AI Assistant')}</b>
      </Typography>
      <Typography variant="body1">
        {t('The AI assistant can help you solve the problem')}
      </Typography>
      {/* <Btn
        variant="contained"
        sx={{ m: 2 }}
        onClick={onStartChat}
        loading={loading}
        fakeDelayMs={1000}
      >
        {t('Get help')}
      </Btn> */}
      <FetcherSelect
        label={t("Device", { count: 1 })}
        value={relevDeviceId}
        onChange={(e) => setRelevDeviceId(e.target.value as number)}
        options={useGetDevicesQuery}
        formControlProps={{
          sx: { mt: 3, maxWidth: "250px" }, fullWidth: true
        }}

      />
    </Stack>
  );
}
