import { Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Pie,
  PieChart,
  Bar,
  BarChart,
} from "recharts";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { LineChart, Line, Legend } from "recharts";
import { withAuth } from "../auth/authWrappers";
// title, children, and ...other, which are all the Grid props
type DashboardCardProps = {
  title: string;
  children: React.ReactNode;
} & React.ComponentProps<typeof Grid>;

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  children,
  ...other
}) => (
  <Grid xs={12} sm={12} md={6} lg={4} {...other}>
    <Card
      sx={{
        bgcolor: "#FFFFFF",
        borderRadius: 3,
        padding: 3,
        boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
        // marginTop: "10px",
      }}
    >
      <Typography color="textSecondary" sx={{ pb: 2 }}>
        <b>{title}</b>
      </Typography>
      {children}
    </Card>
  </Grid>
);

function ChartViewer() {
  const data_energy = [
    {
      name: "0",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "1",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "2",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "3",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "4",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "5",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "6",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const data_temperature = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const data01 = [
    {
      name: "Group A",
      value: 400,
    },
    {
      name: "Group B",
      value: 300,
    },
    {
      name: "Group C",
      value: 300,
    },
    {
      name: "Group D",
      value: 200,
    },
    {
      name: "Group E",
      value: 278,
    },
    {
      name: "Group F",
      value: 189,
    },
  ];
  const data02 = [
    {
      name: "Group A",
      value: 2400,
    },
    {
      name: "Group B",
      value: 4567,
    },
    {
      name: "Group C",
      value: 1398,
    },
    {
      name: "Group D",
      value: 9800,
    },
    {
      name: "Group E",
      value: 3908,
    },
    {
      name: "Group F",
      value: 4800,
    },
  ];

  const data_bar = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <>
      <Stack justifyContent="space-between" direction="row">
        <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
          <b>Charts viewer</b>
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <DashboardCard title="Engines temperature">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={data_temperature}
              // margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="uv" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#82ca9d"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </DashboardCard>

        <DashboardCard title="Products distribution">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data02}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#82ca9d"
                label
              />
            </PieChart>
          </ResponsiveContainer>
        </DashboardCard>

        <DashboardCard title="KPI" md={12}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data_bar}>
              <Bar dataKey="uv" fill="#8884d8" />
              <YAxis />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </DashboardCard>

        <DashboardCard title="Energy consumption" xs={12} sm={12} md={8} lg={8}>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={data_energy}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="uv"
                stroke="#20AF24"
                fill="#79E381"
              />
            </AreaChart>
          </ResponsiveContainer>
        </DashboardCard>

        <DashboardCard title="Production" xs={12} sm={12} md={4} lg={4}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "300px" }}
          >
            <Typography
              sx={{ fontSize: 75 }}
              color="text.secondary"
              gutterBottom
            >
              <b>1256</b>
            </Typography>
          </Stack>
        </DashboardCard>
      </Grid>
    </>
  );
}

export default withAuth(ChartViewer);
