import { BrowserRouter } from "react-router-dom";
import NavDrawer, { RenderNavItemsProps } from "./features/navigation/NavDrawer";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import DebugPage from "./features/debug";
import { Role } from "./features/auth/authWrappers";
import AuthContextProvider from "./features/auth/AuthProvider";
import Login from "./features/auth/Login";
import { AssistantProvider } from "./features/assistant/useAssistantContext";
import './i18n';
import { ThemeProvider } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import theme from "./theme";
import { useTranslation } from "react-i18next";
import NoteListPage from "./features/note/NoteListPage";
import DevicePage from "./features/device/DevicePage";
import SettingsPage from "./features/Settings";
import LogViewer from "./features/logging/logViewer";
import ProfilePage from "./features/auth/Profile";
import FileManagerPage from "./features/file-manager/FileManagerPage";
import ChartViewer from "./features/charts/chartViewer";
import AnomalyViewer from "./features/charts/anomalyViewer";
import TimelineIcon from '@mui/icons-material/Timeline';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import EditNoteIcon from '@mui/icons-material/EditNote';
function App() {

  const { t } = useTranslation();

  const renderNavItems: RenderNavItemsProps = (props) => ([
    // {
    //   icon: <DashboardOutlinedIcon sx={{ color: theme.app.nav.color }} />,
    //   text: t("Dashboard"),
    //   url: "/",
    //   isVisible: props.authContext.isLoggedIn,
    //   position: "top",
    // },
    {
      icon: <ListAltOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Log Viewer"),
      url: "/",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <PrecisionManufacturingOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Device", { count: 2 }),
      url: "/devices",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <EditNoteIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Note", { count: 2 }),
      url: "/notes",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <FolderOpenOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: t("File manager"),
      url: "/files",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <TimelineIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Charts"),
      url: "/charts",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <CrisisAlertIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Anomalies"),
      url: "/anomalies",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <BugReportOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Debug"),
      url: "/debug",
      isVisible: props.authContext.isLoggedIn && process.env.NODE_ENV === "development",
      position: "top",
    },
    {
      icon: <AccountCircleOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: props.authContext.user ? props.authContext.user.username : "Login",
      url: props.authContext.user ? "/profile" : "/login",
      position: "bottom",
    },
    {
      icon: <HelpOutlineIcon sx={{ color: theme.app.nav.color }} />,
      text: "Contact us",
      url: "https://www.mastranet.ai/contact-us",
      isVisible: true,
      position: "bottom",
    },
    {
      icon: <SettingsOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: "Settings",
      url: "/settings",
      isVisible: props.authContext.isLoggedIn,
      position: "bottom",
    }
  ])



  return (
    <ThemeProvider theme={theme}>

      <BrowserRouter>
        <AuthContextProvider>
          <AssistantProvider>

            <SnackbarProvider autoHideDuration={4000} />
            <CssBaseline />
            <NavDrawer renderNavItems={renderNavItems}>
              <Routes>
                <Route
                  path="/charts"
                  element={<ChartViewer allowedRoles={[Role.User]} />}
                />
                <Route
                  path="/anomalies"
                  element={<AnomalyViewer></AnomalyViewer>}
                />

                <Route
                  path="/profile"
                  element={<ProfilePage allowedRoles={[Role.User]} />}
                />
                <Route
                  path="/files"
                  element={<FileManagerPage allowedRoles={[Role.User]} />}
                />
                <Route
                  path="/"
                  element={<LogViewer allowedRoles={[Role.User]} />}
                />
                <Route
                  path="/settings"
                  element={<SettingsPage allowedRoles={[Role.User]} />}
                />
                <Route
                  path="/devices"
                  element={<DevicePage allowedRoles={[Role.User]} />}
                />
                <Route
                  path="/notes"
                  element={<NoteListPage allowedRoles={[Role.User]} />}
                />
                <Route path="/debug" element={<DebugPage />} />
                <Route path="/login" element={<Login />} />

              </Routes>
            </NavDrawer>
          </AssistantProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
