import React from "react";
import { Stack, Typography } from "@mui/material";
import { withAuth } from "../auth/authWrappers";

const FileManagerPage: React.FC = () => {
  return (
    <>
      <Stack justifyContent="space-between" direction="row">
        <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
          <b>File manager</b>
        </Typography>
      </Stack>
      <iframe
        // TODO: this does not work in development, it is directly working on the production server
        src="/filebrowser/"
        width="100%"
        frameBorder="0"
        title="Knowledge Base"
        style={{
          height: "90vh",
          width: "100%",
          border: "none",
          overflow: "hidden",
        }}
      ></iframe>
    </>
  );
};

export default withAuth(FileManagerPage);
