import { useEffect } from "react";
import { ILogRecord, IRelatedLogRecordResult } from "../../app/services/apiTypes";
import { INoteRequest } from "./noteTypes";
import { Box, Chip, Paper, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/system/Stack";
import { useForm, Controller } from "react-hook-form";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import AssistantOutlinedIcon from "@mui/icons-material/AssistantOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import {
  useGetRelatedLogRecordsQuery,
  useCreateNoteMutation,
} from "../../app/services/appApi";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from '@mui/lab';

export default function NoteLogBar({ logRecord }: { logRecord: ILogRecord }) {
  const { t } = useTranslation();
  const { data: relatedLogRecords } = useGetRelatedLogRecordsQuery(logRecord.id);
  const [createNote, { isLoading, isError, isSuccess }] = useCreateNoteMutation();

  // Handle create note success & error
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Note created"), { variant: "success" });
    } else if (isError) {
      enqueueSnackbar(t("Note creation failed"), { variant: "error" });
    }
  }, [isSuccess, isError, t]);

  const { handleSubmit, control } = useForm<INoteRequest>({
    defaultValues: {
      log_record_ids: [logRecord.id],
      body: "",
    },
  });

  const relatedLogs: ILogRecord[] = (relatedLogRecords || [])
    .filter((res) => res.match === "similar")
    .map((res) => res.log_record);
  // const onSubmit = data => console.log(data);

  return (
    <Stack gap={4}>
      {/* Related docs */}
      <Box>
        <Stack direction="row" sx={{ mb: 2, color: "#6f6f6f" }}>
          <PlagiarismOutlinedIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            <b>{t('Related Resources')}</b>
          </Typography>
        </Stack>

        <Button component="a">
          &#9679;
          <span style={{ marginLeft: "10px" }}>20230623_err_macchina.pdf</span>
          <br />
        </Button>
        <Button component="a">
          &#9679;
          <span style={{ marginLeft: "10px" }}>20230623_err_macchina.pdf</span>
        </Button>
      </Box>

      {/* Related logs */}
      <Box>
        <Stack direction="row" sx={{ mb: 2, color: "#6f6f6f" }}>
          <ListAltIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            <b>{t('Similar Errors')}</b>
          </Typography>
        </Stack>

        {relatedLogs.length > 0 ? (
          relatedLogs.map((l: ILogRecord, index: number) => (
            <Button component="a">
              &#9679;<span style={{ marginLeft: "10px" }}>{l.body}</span>
              <br />
            </Button>
          ))
        ) : (
          <Typography variant="body2" color="GrayText" sx={{ mt: 3, ml: 1 }}>
            {t('No similar logs')}
          </Typography>
        )}
      </Box>

      {/* Existing notes */}
      <Box>
        <Stack direction="row" sx={{ mb: 2, color: "#6f6f6f" }}>
          <AssistantOutlinedIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            <b>{t('Proposed Solutions')}</b>
          </Typography>
        </Stack>

        {relatedLogRecords && relatedLogRecords.length > 0 ? (
          relatedLogRecords.map((res, index: number) => (
            <NoteStatic key={index} relatedLogRecordResult={res} />
          ))
        ) : (
          <Typography variant="body2" color="GrayText" sx={{ mt: 3, ml: 1 }}>
            {t('No solution found')}
          </Typography>
        )}
      </Box>

      {/* New note */}
      <Box>
        <Stack direction="row" sx={{ mb: 2, color: "#6f6f6f" }}>
          <TipsAndUpdatesOutlinedIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            <b>{t('Add new solution')}</b>
          </Typography>
        </Stack>

        <form onSubmit={handleSubmit(createNote)}>
          <Controller
            name="body"
            control={control}
            render={({ field }) => (
              <TextField
                multiline
                fullWidth
                minRows={3}
                placeholder={t('Write something...')}
                {...field}
              />
            )}
          />
          {/* Actions */}
          <Stack direction="row" sx={{ mt: 1 }} justifyContent="right">
            <LoadingButton loading={isLoading} size="small" type="submit">
              {t('Submit')}
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Stack >
  );
}

function NoteStatic({ relatedLogRecordResult }: { relatedLogRecordResult: IRelatedLogRecordResult }) {
  const { t } = useTranslation();

  return (
    <Paper variant="outlined" elevation={0} sx={{ p: 3 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1" sx={{}}>
          {t('Solution 1')}
        </Typography>
        {relatedLogRecordResult.match === "similar" && (
          <Chip
            label={t('Smart Tip')}
            color="primary"
            size="small"
            icon={<AutoFixHighIcon />}
          />
        )}
        {relatedLogRecordResult.match === "exact" && (
          <Chip
            label={t('Verified')}
            color="success"
            size="small"
            icon={<VerifiedIcon />}
          />
        )}
      </Stack>
      {relatedLogRecordResult.match === "similar" && (
        <>
          <Typography variant="caption" color="GrayText" sx={{ mt: 2 }}>
            <b>{t('Similar to')}:</b> {relatedLogRecordResult.log_record.body}
            <br />
          </Typography>
          <Typography variant="caption" color="GrayText" sx={{ mt: 2 }}>
            <b>{t('Similarity')}: </b>
            {(relatedLogRecordResult.score * 100).toFixed(0)}%
          </Typography>
        </>
      )}
      <Typography variant="body2" color="GrayText" sx={{ mt: 2 }}>
        {relatedLogRecordResult.log_record.note?.body}
      </Typography>
    </Paper>
  );
}
