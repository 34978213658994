import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Input } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Stack } from "@mui/system";
import useAuthContext from "../auth/AuthContext";
import {
  useDebugInfoQuery,
  useCreateLogRecordMutation,
  useForceReindexAllFilesMutation,
  useForceReindexAllNotesMutation,
} from "../../app/services/appApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// IMport mui loadingButton
import LoadingButton from "@mui/lab/LoadingButton";
import Quill from "../note/Quill";
import Viewer from "../viewer/viewer";

const DeveloperPage: React.FC = () => {
  const { data } = useDebugInfoQuery();
  const [createLogRecord] = useCreateLogRecordMutation();



  const [reindexFiles, { isLoading, isError, isSuccess }] =
    useForceReindexAllFilesMutation();

  const [reindexNotes, { isLoading: isNotesLoading, isError: isNotesError, isSuccess: isNotesSuccess }] =
    useForceReindexAllNotesMutation();

  const [message, setMessage] = useState("");
  enum Status {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
  }
  const [level, setLevel] = useState<Status>(Status.INFO);

  // Display notification when reindex is done or failed
  useEffect(() => {
    if (isSuccess || isNotesSuccess) {
      enqueueSnackbar("Reindex done", { variant: "success" });
    }
    if (isError || isNotesError) {
      enqueueSnackbar("Reindex failed", { variant: "error" });
    }
  }, [isSuccess, isError, isNotesSuccess, isNotesError]);


  const [loremIpsum, setLoremIpsum] = useState<"true" | "false">(
    localStorage.getItem("dbgLoremChat") === "true" ? "true" : "false"
  );

  useEffect(() => {
    if (loremIpsum === "true") {
      localStorage.setItem("dbgLoremChat", "true");
    }
    if (loremIpsum === "false") {
      localStorage.setItem("dbgLoremChat", "false");
    }
  }, [loremIpsum]);


  return (
    <>
      <Typography variant="h4">Debug page</Typography>
      <Stack spacing={2}>
        <Typography variant="h6">Notification stack</Typography>
        <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
          {["error", "warning", "info", "success"].map((variant: any) => (
            <Button
              variant="contained"
              color={variant as any}
              key={variant}
              onClick={() => enqueueSnackbar("Hello world!", { variant })}
            >
              Show {variant} snackbar
            </Button>
          ))}
        </Stack>
        <Typography variant="h6">Login/Logout</Typography>
        <LoginLogout />
        <Typography variant="h6">Frontend info</Typography>
        <Typography variant="body1" component="p">
          Version: {process.env.REACT_APP_VERSION}
          <br />
          Environment: {process.env.NODE_ENV}
        </Typography>
        <Typography variant="h6">Backend info</Typography>
        <Typography variant="body1" component="p">
          {JSON.stringify(data)}
        </Typography>
        <Typography variant="h6">Generate logrecord from machinery</Typography>

        <Input
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          inputProps={{ "aria-label": "description" }}
        />

        <Select
          value={level}
          onChange={(e) => setLevel(e.target.value as Status)}
        >
          <MenuItem value={Status.INFO}>Info</MenuItem>
          <MenuItem value={Status.WARNING}>Warning</MenuItem>
          <MenuItem value={Status.ERROR}>Errors</MenuItem>
        </Select>

        <Button
          variant="contained"
          onClick={() => {
            createLogRecord({
              device_id: 1,
              body: message,
              level: level,
            });
            setMessage("");
          }}
        >
          Generate logrecord
        </Button>

        <Typography variant="h6">Reindex</Typography>
        <LoadingButton
          variant="contained"
          onClick={() => reindexFiles()}
          loading={isLoading}
        >
          Reindex files
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={() => reindexNotes()}
          loading={isNotesLoading}
        >
          Reindex notes
        </LoadingButton>


        <Typography variant="h6">Lorem ipsum chat</Typography>
        <LoadingButton
          variant="contained"
          onClick={() =>
            setLoremIpsum(loremIpsum === "true" ? "false" : "true")
          }

        >
          {loremIpsum === "true" ? "Disable" : "Enable"} lorem ipsum chat
        </LoadingButton>



        {/* <Viewer
          open
          resource={
            {
              src_ref_type: "NOTE",//| "FILE",
              src_ref_id: "38",//| "FILE",
              // thumbnail: "1718714626.097146.jpg",
              uri: "string",
              title: "Titolo",
              content: "ontenuto",
              score: 1,
            }
          }
          onClose={(e: any) => { }}
        />

        */}

      </Stack>
    </>
  );
};

const LoginLogout: React.FC = () => {
  const { user, login, logout } = useAuthContext();

  return (
    <>
      <Button
        variant="contained"
        onClick={() => login({ username: "test", password: "test" })}
      >
        Login (wrong credentials)
      </Button>
      <Button variant="contained" onClick={() => logout()}>
        Logout
      </Button>
      <Typography variant="body1">
        {user === undefined
          ? "Loading..."
          : user
            ? `Logged in as ${user.username}`
            : "Not logged in"}
      </Typography>
    </>
  );
};

export default DeveloperPage;
