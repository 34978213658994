import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
// import { XTerm } from "xterm-for-react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TerminalIcon from "@mui/icons-material/Terminal";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next"; // 1. Add the import

interface RemoteShellDialogProps extends React.ComponentProps<typeof Button> { }

export default function RemoteShellDialog({
  ...buttonProps
}: RemoteShellDialogProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, [open]);

  // const xtermRef = useRef(null);

  // useEffect(() => {
  //   // You can call any method in XTerm.js by using 'xterm xtermRef.current.terminal.[What you want to call]
  //   if (!xtermRef.current) return;
  //   //@ts-ignore-next-line
  //   xtermRef.current.terminal.writeln("Hello, World!");
  // }, []);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        startIcon={<TerminalIcon />}
        size="large"
        {...buttonProps}
      >
        {t("Remote Shell")}
      </Button>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{t("Remote Shell")}</DialogTitle>
        <DialogContent>
          {/* <XTerm ref={xtermRef} /> */}
          <Box
            sx={{
              height: "450px",
              backgroundColor: "black",
              p: 2,
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CircularProgress sx={{ color: "white" }} />
              </Box>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  whiteSpace: "pre",
                  fontFamily: "monospace",
                }}
              >
                {`[luca@ThinkpadT14 log]$ ls -l

total 1300
drwx------  2 root root              4096 21 apr  2022 audit
-rw-rw----  1 root utmp               384 13 mar 10.19 btmp
-rw-rw----  1 root utmp               384 26 feb 15.31 btmp.1
drwxr-xr-x  2 root root              4096 20 mar 09.08 cups
drwxr-xr-x  2 root root              4096  6 giu  2022 gssproxy
-rw-r--r--  1 root root             19219  2 mar 11.53 haskell-register.log
drwxr-sr-x+ 4 root systemd-journal   4096 28 ott 13.10 journal
-rw-rw-r--  1 root utmp            292292 13 mar 10.20 lastlog
drwxr-xr-x  2 root root              4096  9 giu  2022 old
-rw-r--r--  1 root root            383512 14 mar 20.27 pacman.log
drwx------  2 root root              4096 16 ago  2022 private
drwxr-xr-x  2 root root              4096  8 ago  2022 samba
-rw-rw-r--  1 root utmp            739968 22 mar 18.29 wtmp
-rw-r--r--  1 root root             87354 22 mar 09.09 Xorg.0.log
-rw-r--r--  1 root root             37021 21 mar 00.33 Xorg.0.log.old

[luca@ThinkpadT14 log]$ _


`}
              </Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
